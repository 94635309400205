import { Component, OnInit } from '@angular/core';
import { ActivationEnd } from '@angular/router';
import { RouterService } from '../../services/router.service';
import { ApiService } from '../../services/api.service';
import { DatesService } from '../../services/dates.service';
import { DialogService } from '../../services/dialog.service';
import { OrdersService } from '../../services/orders.service';
import { AuthService } from '../../services/auth.service';
import { DatabaseService } from '../../services/database.service';
import { Tracking } from '../../classes/tracking';
import { Order } from '../../classes/order';
import { MediaObserver } from '@angular/flex-layout';
import { Page } from '../../adapters/page';
import { StateSharedService } from 'src/app/services/state-shared.service';
import { Subscription } from 'rxjs';
import { SerializeHelper } from 'src/app/datasource/helper/serialize.helper';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { DocGCInterface } from 'src/app/classes/document-logistic';

export interface DoucmentElement {
	tipodedocumento: string;
	estado: string;
	file: string;
	active: boolean;
}
const DOUCMENT_DATA: DoucmentElement[] = [
	{
		tipodedocumento: 'ORDEN DE COMPRA',
		estado: 'Descargar',
		file: "orden_compra",
		active: false
	},
	{
		tipodedocumento: 'FACTURA',
		estado: 'Descargar',
		file: "factura",
		active: false
	},
	{
		tipodedocumento: 'ADICIONAL',
		estado: 'Descargar',
		file: "adicionales",
		active: false
	},
	{
		tipodedocumento: 'ENTREGAS',
		estado: 'Descargar',
		file: "entregas",
		active: false
	},
];

@Component({
	selector: 'app-invoice',
	templateUrl: './invoice.component.html',
	styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

	year: number = (new Date()).getFullYear();

	pedido: string;
	invoice: string;

	tracking: Tracking;
	messages: { mensaje: string; fecha: string; }[] = [];
	order: Order;

	orderLoaded: boolean;

	maxDate: Date = new Date();

	_loading: boolean = false;

	dataSourceThree = DOUCMENT_DATA;

	displayedColumnsThree: string[] = ['tipodedocumento', 'estado'];

	get loading(): boolean { return this._loading }

	set loading(value: boolean) { this._loading = value; }

	gcDocuments:DocGCInterface[]  = [];

	constructor(private routerService: RouterService,
		private authService: AuthService,
		private database: DatabaseService,
		public datesService: DatesService,
		private dialogService: DialogService,
		public ordersService: OrdersService,
		private api: ApiService,
		private media: MediaObserver,
		private _page: Page,
		private stateSharedService: StateSharedService<any>,
		private snackbar: SnackbarService) {

		this._page.actionBarHidden = true;

		this.router.events.subscribe(event => {

			if (event instanceof ActivationEnd) {
				if (event.snapshot.url.length == 0) return;
				this.pedido = event.snapshot.url[event.snapshot.url.length - 2].path;
				this.invoice = event.snapshot.url[event.snapshot.url.length - 1].path;

				this.api.tracking(this.pedido).then(tracking => {
					tracking.tracking.factura.documentos = tracking.tracking.factura.documentos
						.filter((document) => (Number(document.numero) === Number(this.invoice) && document.tipo === 'M') || document.tipo !== 'M');
					this.tracking = tracking.tracking;
					this.messages = tracking.mensajes;
				}).catch((error) => {
					this.tracking = ('' as any);
					return this.snackbar.show('No podemos obtener la información de tus documentos, por favor verifica nuevamente o comunicate con el administrador de este servicio');
				});

				this.ordersService.orderWithPurchase(this.pedido).subscribe(order => {

					this.order = order;
					this.orderLoaded = true;
				}, (error) => this.snackbar.show(error.message));

				this.getDocumentsGC()
			}
		});
	}

	get router() { return this.routerService.router; }

	get xs(): boolean {
		return this.media.isActive('xs');
	}

	stringToDate(string: string): Date {

		const doo = new Date(string);

		return new Date(doo.getTime() - doo.getTimezoneOffset() * -60000);
	}

	filter!: Subscription;
	data!: Subscription;
	ngOnInit() {
		this.filter = this.stateSharedService.get('filter').subscribe((result) => {
			console.log("🚀 ~ file: invoice.component.ts:120 ~ InvoiceComponent ~ this.stateSharedService.get ~ result:", result)
		});
	}

	goToPage() {
		window.history.go(-1);
		return false;
	}

	ngOnDestroy() {
		this.filter?.unsubscribe();
	}

	async getDocumentsGC() {
		try {

			//consultar dos GC
			const docsGC: DocGCInterface[] = await this.api.documentLogisticGC(this.pedido)

			this.gcDocuments = docsGC
			
		} catch (error) {
			this.snackbar.show(error.message);
		}
	}



	async getDocuments(field: string) {
		try {
			let docs: { name: string, url: string }[] = [];
			if (field == 'entregas') {
				this.gcDocuments.forEach((docGc:DocGCInterface, index) => {
					docs.push({
						name: 'FOTO '+(index+1),
						url: docGc.path
					});
				});

				this.dialogService.openDialog({
					type: 'documents',
					object: { docs: docs }
				});

				return;
			}

			const response = await this.api.documentLogistic(this.pedido);
			const order = response[0];
			if (!order || order == ('' as any)) throw new Error('El pedido no se pudo cargar desde sap.');
			if (!this.invoice || this.invoice == ('' as any)) throw new Error('La factura no se pudo cargar.');
			if (!this.tracking || this.tracking == ('' as any)) throw new Error('El pedido no se pudo cargar desde GC');
			const documentsRef = [];

			SerializeHelper.search(documentsRef, response[0], 'number', this.invoice);
			const documents = !documentsRef ? [] : documentsRef;
			const documentsString = JSON.stringify(documents);

			if (field == 'despacho') {
				for (var despacho of this.tracking.despacho.documentos || []) {
					const exists = documentsString.indexOf(despacho.numero) != -1;
					if (exists) docs.push({
						name: despacho.numero,
						url: despacho.url
					});
				}
			}
			else if (field == 'orden_compra') {
				docs.push({
					name: 'Orden de compra',
					url: this.tracking.orden_compra.url, //url: 'https://gc.multigroup.com/sales/pedidos/cotizacion/'+this.tracking.cotizacion.id+'/pdfapp/'
				});
			}
			else if (field == 'factura') {
				for (var factura of this.tracking.factura.documentos || []) {
					const exists = documentsString.indexOf(factura.numero) != -1;
					if (exists) docs.push({
						name: String(factura.numero).trim()[0] == '5' ? "Factura (" + factura.numero + ")" : String(factura.numero).trim()[0] == '6' ? "Nota (" + factura.numero + ")" : factura.numero,
						url: 'https://gc.multigroup.com/' + factura.url
					});
				}
			}
			else if (field == 'adicionales') {
				var i = 0
				for (var adicional of this.tracking.adicionales.documentos || []) {
					i++
					docs.push({
						name: 'Entrega ' + String(i),
						url: adicional.url
					});
				}
			}

			if (docs.length > 0) {
				this.dialogService.openDialog({
					type: 'documents',
					object: { docs: docs }
				});
			}
		} catch (error) {
			this.snackbar.show(error.message);
		}
	}

}
